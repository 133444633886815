@use 'Styles' as *;

.locationCard {
  height: auto !important;
  margin: 1.34rem;
  background-color: getColor(ui-white-100);
  border-radius: getSize(s30);
  border-color: getColor(ui-grey-75);
}

.locationCard:last-of-type {
  @media screen and (max-width: $tablet-max) {
    margin-bottom: 0rem;
  }
}
