.filterDrawerBody {
  h3.chakra-heading {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}

.drawerFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  > button {
    flex-basis: 50%;
  }
}
