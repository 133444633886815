@use 'styles' as *;
@use './variables.scss' as *;

.filterChipsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: auto;
  white-space: nowrap;
  @include padding-vertical(s30);
  @include padding-horizontal(s0);
  margin: 0 3rem 1.5rem;

  @media (max-width: $tablet-max) {
    margin: 0 1.5rem 0 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  -ms-overflow-style: none;
}

a.clearAllLink {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  gap: 4px;
}
