@use 'Styles' as *;

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.25rem 3rem;
}

.divider {
  margin-bottom: 0;
}
