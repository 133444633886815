@use 'Styles' as *;

.tabContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  a[aria-current='page'] {
    border-bottom: 2px solid #cb333b;
  }
  a[aria-current='page'] > p {
    color: #cb333b;
  }
  a:active,
  a:focus {
    border-bottom: 2px solid #cb333b;
  }
  a:active > p,
  a:focus > p {
    color: #cb333b;
  }

  @media screen and (max-width: $mobile-max) {
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; // Better iOS scrolling
    scrollbar-width: none; // hide scrollbar in Firefox
    -ms-overflow-style: none; // hide scrollbar in IE and Edge
    &::-webkit-scrollbar {
      // hide scrollbar in Chrome
      display: none;
    }
  }
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 1.34rem 1.34rem;
}

.pharmacistLink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.333rem;
  border-bottom: 1px solid #dfdfdf;

  @media screen and (max-width: $newTablet-max) {
    padding: 1.333rem;
    > a {
      font-size: 2rem;
    }
  }
}

.searchBarWrapper {
  justify-self: center;
  width: 450px;

  @media screen and (max-width: $newTablet-max) {
    width: 100%;
    @include padding-left(s50);
    @include padding-right(s30);
  }
}

.findAStoreHeading {
  @include margin-top(s80);
  @include margin-bottom(s60);
  display: flex;
  justify-content: center;

  @media screen and (max-width: $newTablet-max) {
    @include margin-top(s60);
    @include margin-bottom(s50);
  }
  @media screen and (max-width: $mobile-max) {
    justify-content: left;
  }
}

.alertContainer {
  display: flex;
  justify-self: center;
  width: 450px;
  @include padding-top(s50);

  @media screen and (max-width: $newTablet-max) {
    width: 100%;
  }
}

.grid {
  @include padding-top(s80);
  @media screen and (max-width: $newTablet-max) {
    @include padding-top(s20);
  }
}

.searchBarLocationButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
}

.useMyLocationButton {
  @include margin-left(s40);

  @media screen and (max-width: $newTablet-max) {
    @include margin-left(s0);
    @include margin-right(s50);
    @include background-color(ui-grey-75);
  }
}
